/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useContext } from 'react';

import './Components.css';

import { supabaseContext } from '../App';
import { Article } from '../interfaces/Article';

interface Props {
  article: Article,
}

function ArticleCard(props: Props) {
  const supabase = useContext(supabaseContext);
  const { article } = props;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');

  const getImage = async () => {
    const { data } = await supabase.storage.from('Images').download(article.title.substr(0, 8));
    if (data) setImageSrc(URL.createObjectURL(data));
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <div className="containerComponent">
      <img alt="articleLogo" src={imageSrc || 'logo.jpg'} className="articleImg" onClick={() => setModalIsOpen(true)} />
      <div className="articleCore">
        <div
          role="button"
          tabIndex={0}
          className="articleTitle"
          onClick={() => setModalIsOpen(true)}
        >
          {article.title}
        </div>
        <div className="row" style={{ padding: '5%', width: '90%', justifyContent: 'space-between' }}>
          <div className="articleDate">
            {new Date(article.date).toLocaleDateString('fr')}
          </div>
          <div className="articleAuthor">
            {article.author}
          </div>

        </div>
      </div>

      {modalIsOpen && (
        <div className="modalArticle">
          <div className="column" style={{ gap: '40px', width: '100%' }}>
            <div className="articleTitle" style={{ cursor: 'default', fontSize: '50px' }}>
              {article.title}
            </div>

            <div className="separator">
              <div className="articleDate">
                publié le
                {' '}
                {new Date(article.date).toLocaleDateString('fr')}
              </div>
              <div className="articleDate">
                par
                {' '}
                {article.author}
              </div>
            </div>
            <div style={{ margin: '5%', width: '80%', alignItems: 'start' }}>
              <div style={{ float: 'left', marginRight: '5%', marginBottom: '5%' }}>
                <img alt="articleLogo" src={imageSrc || 'photo1.jpg'} className="articleImgBig" onClick={() => setModalIsOpen(true)} />

              </div>
              <div style={{
                float: 'none', fontSize: '22px', whiteSpace: 'pre-wrap',
              }}
              >

                {article.content}
              </div>
            </div>
          </div>

        </div>

      )}
    </div>
  );
}

export default ArticleCard;
