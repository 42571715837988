/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useContext } from 'react';
import './BackOffice.css';

import { supabaseContext } from '../../App';
import Agenda from './agenda/Agenda';
import Articles from './documentation/Articles';
import Testimonies from './testimonies/Testimonies';

function BackOffice() {
  const supabase = useContext(supabaseContext);
  const [session, setSession] = useState<string>('');
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('');

  const tabStyle = { minHeight: '40px' };
  const activeTabStyle = { minHeight: '40px', backgroundColor: '#d50030' };

  async function signInWithEmail() {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: 'sim.lemaitre@gmail.com',
      password: 'saralome',
    });

    if (data?.session && !error) setSession(data.session.access_token);
  }

  function log() {
    if (login === process.env.REACT_APP_LOGIN && password === process.env.REACT_APP_PASSWORD) setSession('letsgo');
  }

  useEffect(() => {
    if (false) signInWithEmail();
  }, []);

  // Login screen
  if (!session) {
    return (
      <div className="containerConnexion" style={{ paddingTop: '10%' }}>
        <div>Utilisateur</div>
        <input
          type="text"
          style={{ width: '30%' }}
          value={login}
          onChange={(event) => setLogin(event.target.value)}
        />
        <div>Mot de passe</div>
        <input
          type="password"
          style={{ width: '30%' }}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <div
          className="buttonAgenda"
          role="button"
          tabIndex={0}
          onClick={log}
        >
          Connexion

        </div>
      </div>
    );
  }

  // Back Office screen
  return (
    <div className="containerConnexion">
      <div className="titleConnexion">
        Espace admnistrateur
      </div>
      <div className="row">
        <div
          className="buttonAgenda"
          role="button"
          tabIndex={0}
          style={activeTab === 'Agenda' ? activeTabStyle : tabStyle}
          onClick={() => setActiveTab('Agenda')}
        >
          Agenda

        </div>
        <div
          className="buttonAgenda"
          role="button"
          tabIndex={0}
          style={activeTab === 'Documentation' ? activeTabStyle : tabStyle}
          onClick={() => setActiveTab('Documentation')}
        >
          Articles
        </div>
        <div
          className="buttonAgenda"
          role="button"
          tabIndex={0}
          style={activeTab === 'Témoignages' ? activeTabStyle : tabStyle}
          onClick={() => setActiveTab('Témoignages')}
        >
          Témoignages
        </div>
      </div>
      {activeTab === 'Documentation' && <Articles />}
      {activeTab === 'Agenda' && <Agenda />}
      {activeTab === 'Témoignages' && <Testimonies />}

    </div>
  );
}

export default BackOffice;
