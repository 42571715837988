/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useContext } from 'react';

import './Components.css';

import { supabaseContext } from '../App';
import { Article } from '../interfaces/Article';

interface Props {
  article: Article,
  reload: () => void,
}

function ArticleCardBO(props: Props) {
  const supabase = useContext(supabaseContext);
  const { article, reload } = props;
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const [imageSrc, setImageSrc] = useState<string>('');

  const getImage = async () => {
    const { data } = await supabase.storage.from('Images').download(article.title.substr(0, 8));
    if (data) setImageSrc(URL.createObjectURL(data));
  };

  const deleteArticle = async () => {
    const { error } = await supabase.from('articles').delete()
      .eq('id', article.id);
    if (error) setErr('Impossible de supprimer l\'article');
    if (!error) reload();
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <div className="containerComponent">
      <img alt="articleLogo" src={imageSrc || 'photo1.jpg'} className="articleImg" />
      <div className="articleCore">
        <div className="articleTitle">
          {article.title}
        </div>
        <div className="row" style={{ padding: '5%', width: '90%', justifyContent: 'space-between' }}>
          <div className="articleDate">
            {new Date(article.date).toLocaleDateString('fr')}
          </div>
          <div className="articleAuthor">
            {article.author}
          </div>

          <div
            role="button"
            tabIndex={0}
            style={{ height: '30px', width: '150px', cursor: 'pointer' }}
            onClick={() => setConfirmModalIsOpen(true)}
          >
            🗑️
          </div>
        </div>
      </div>
      {err && <div style={{ color: 'red' }}>{err}</div>}
      {confirmModalIsOpen && (
      <div className="modal">
        <div className="containerModal" style={{ width: '600px', height: '150px', borderRadius: '20px' }}>
          Vous allez supprimer cet article, confirmer ?
          <div className="row">
            <div
              className="buttonAgenda"
              role="button"
              tabIndex={0}
              onClick={() => setConfirmModalIsOpen(false)}
            >
              Annuler
            </div>
            <div
              className="buttonAgenda"
              role="button"
              tabIndex={0}
              onClick={deleteArticle}
            >
              Confirmer
            </div>
          </div>
        </div>

      </div>
      )}
    </div>

  );
}

export default ArticleCardBO;
