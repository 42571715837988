/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useEffect, useState, useCallback, useContext,
} from 'react';

import { supabaseContext } from '../../../App';
import { Testimony } from '../../../interfaces/Testimony';
import TestimonyCardBO from '../../../components/TestimonyCardBO';

const initTestimony:Testimony = {
  id: 0,
  author: '',
  core: '',
  color: '#FFFFFFF',
};

function Testimonies() {
  const supabase = useContext(supabaseContext);
  const [testimonies, setTestimonies] = useState<Array<Testimony>>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const [newTestimony, setNewTestimony] = useState<Testimony>(initTestimony);

  const getTestimonies = useCallback(async () => {
    const { data } = await supabase.from('testimonies').select(`
    id, 
    author,
    core,
    color
  `);
    if (data) setTestimonies(data);
  }, []);

  const reload = () => {
    getTestimonies();
  };

  const onSave = async () => {
    const previousIndex = testimonies[testimonies.length - 1]?.id || 0;

    const { error } = await supabase
      .from('testimonies')
      .insert({ ...newTestimony, id: previousIndex + 1 });

    if (error) {
      setErr('Impossible de créer le témoignage');
    } else {
      reload();
      setModalIsOpen(false);
    }
  };

  const onAuthorChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewTestimony({ ...newTestimony, author: e.currentTarget.value });
  };

  const onCoreChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setNewTestimony({ ...newTestimony, core: e.currentTarget.value });
  };

  useEffect(() => {
    getTestimonies();
  }, []);

  return (
    <>
      <div className="containerAgenda">
        {testimonies && testimonies.map((testimony) => (
          <TestimonyCardBO
            key={testimony.id}
            testimony={testimony}
            reload={reload}
          />
        ))}
        <div
          className="registerButton"
          role="button"
          tabIndex={0}
          style={{ width: '20%', height: '40px' }}
          onClick={() => setModalIsOpen(!modalIsOpen)}
        >
          Ajouter témoignage

        </div>
      </div>
      {modalIsOpen && (
      <div className="modalAddSession">
        <div className="containerAddSession">
          <div>AJOUTER UN TEMOIGNAGE</div>
          <div className="row">
            <div className="column">
              <div>Auteur</div>
              <input
                type="text"
                className="contactInput"
                placeholder="Simon"
                value={newTestimony.author}
                onChange={onAuthorChange}
              />
              <div>Témoignage</div>
              <textarea
                className="contactInputMessage"
                placeholder="Super sympa"
                name="core"
                value={newTestimony.core}
                onChange={onCoreChange}
              />
            </div>
          </div>
          {err && <div style={{ color: 'red' }}>{err}</div>}
          <div className="row">
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={() => setModalIsOpen(!modalIsOpen)}
            >
              Annuler

            </div>
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={onSave}
            >
              Confirmer

            </div>

          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default Testimonies;
