/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useEffect, useState, useCallback, useContext,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { supabaseContext } from '../../../App';
import { Session } from '../../../interfaces/Session';
import RegisterCardBO from '../../../components/RegisterCardBO';

const initSession:Session = {
  id: 0,
  month: 'Janvier',
  price: 900,
  firstEvent: new Date(),
  secondEvent: new Date(),
  thirdEvent: new Date(),
};

function Agenda() {
  const supabase = useContext(supabaseContext);
  const [sessions, setSessions] = useState<Array<Session>>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [newSession, setNewSession] = useState<Session>(initSession);

  const getSessions = useCallback(async () => {
    const { data } = await supabase.from('sessions').select(`
    id, 
    month,
    price,
    firstEvent,
    secondEvent,
    thirdEvent
  `);
    if (data) setSessions(data);
  }, []);

  const onDateChange = (date: Date, event: string) => {
    switch (event) {
      case 'firstEvent':
        setNewSession({ ...newSession, firstEvent: date });
        break;
      case 'secondEvent':
        setNewSession({ ...newSession, secondEvent: date });
        break;
      case 'thirdEvent':
        setNewSession({ ...newSession, thirdEvent: date });
        break;
      default:
    }
  };

  const onPriceChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewSession({ ...newSession, price: e.currentTarget.valueAsNumber });
  };

  const onMonthChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewSession({ ...newSession, month: e.currentTarget.value });
  };

  const reload = () => {
    getSessions();
  };

  const onSave = async () => {
    const { error } = await supabase
      .from('sessions')
      .insert({ ...newSession, id: undefined });

    if (!error) {
      reload();
      setModalIsOpen(false);
    }
  };

  useEffect(() => {
    getSessions();
  }, []);

  return (
    <>
      <div className="containerAgenda">
        <div className="row">
          {sessions.length === 0 && <div>AUCUNE SESSION PREVUE</div>}
          {sessions && sessions.map((session) => (
            <RegisterCardBO
              key={session.id}
              reload={reload}
              session={session}
            />
          ))}
        </div>
        <div
          className="registerButton"
          role="button"
          tabIndex={0}
          style={{ width: '20%', minHeight: '40px' }}
          onClick={() => setModalIsOpen(!modalIsOpen)}
        >
          Ajouter session
        </div>
      </div>

      {modalIsOpen && (
      <div className="modalAddSession">
        <div className="containerAddSession">
          <div>AJOUTER UNE SESSION</div>
          <div className="row">
            <div className="column">
              <div>Titre/Mois</div>
              <input
                type="text"
                value={newSession.month}
                onChange={onMonthChange}
              />
              <div>Prix</div>
              <input
                type="number"
                value={newSession.price}
                onChange={onPriceChange}
              />
            </div>

            <div className="column">
              <div>Première séance</div>
              <DatePicker
                selected={newSession.firstEvent}
                onChange={(date: Date) => onDateChange(date, 'firstEvent')}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy"
                calendarStartDay={1}
              />
              <div>Deuxième séance</div>
              <DatePicker
                selected={newSession.secondEvent}
                onChange={(date: Date) => onDateChange(date, 'secondEvent')}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy"
                calendarStartDay={1}
              />
              <div>Troisième séance</div>
              <DatePicker
                selected={newSession.thirdEvent}
                onChange={(date: Date) => onDateChange(date, 'thirdEvent')}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy"
                calendarStartDay={1}
              />
            </div>

          </div>
          <div className="row">
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={() => setModalIsOpen(!modalIsOpen)}
            >
              Annuler

            </div>
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={onSave}
            >
              Confirmer

            </div>

          </div>
        </div>
      </div>
      )}

    </>
  );
}

export default Agenda;
