import './Views.css';
import {
  useContext, useEffect,
} from 'react';

import emailjs from '@emailjs/browser';
import { supabaseContext } from '../App';
import { User } from '../interfaces/User';

function Presentation() {
  const supabase = useContext(supabaseContext);
  const userInfoString = window.localStorage.getItem('userInfo');
  const userInfo = (userInfoString !== null && userInfoString !== '') ? JSON.parse(userInfoString) : '';
  const sessionInfoString = window.localStorage.getItem('sessionInfo');
  const sessionInfo = (sessionInfoString !== null && sessionInfoString !== '') ? JSON.parse(sessionInfoString) : '';

  const sendEmail = () => {
    const mailParam = {
      ...sessionInfo,
      name: userInfo.name.toUpperCase(),
      first_name: userInfo.firstName,
      client_mail: userInfo.mail,
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE as string, 'template_xwnf94k', mailParam, process.env.REACT_APP_EMAILJS_API_KEY as string);
  };

  const onSave = async (user: User) => {
    await supabase
      .from('users')
      .insert(user);
  };

  useEffect(() => {
    if (userInfo !== null && userInfo !== '') {
      onSave(userInfo);
      sendEmail();

      // window.localStorage.setItem('userInfo', '');
      // window.localStorage.setItem('sessionInfo', '');
    }
  }, []);

  return (
    <div className="containerArticles">
      <div className="paymentVadidate">
        <img alt="validate" src="validate.png" width="10%" />
        Votre paiement a bien été validé, vous allez recevoir un email
        de confirmation.
      </div>
      {userInfo && (
      <div>
        Résumé :
      </div>
      )}
    </div>
  );
}

export default Presentation;
