import { useState, useEffect, useContext } from 'react';

import { isMobileContext, supabaseContext } from '../App';
import RegisterCard from '../components/RegisterCard';
import { Session } from '../interfaces/Session';

function Register() {
  const supabase = useContext(supabaseContext);
  const [sessions, setSessions] = useState<Array<Session>>([]);
  const isMobile = useContext<boolean>(isMobileContext);

  async function getSessions() {
    const { data } = await supabase.from('sessions').select(`
    id, 
    month,
    price,
    firstEvent,
    secondEvent,
    thirdEvent
  `);
    if (data) setSessions(data);
  }

  useEffect(() => {
    getSessions();
  }, []);

  return (

    <div className="containerView">
      <p className="registerTitle">
        Le
        {' '}
        <span className="redText"> transfert de compétences</span>
        {' '}
        sur le TDA/H de l'adulte
        se déroule sur
        {' '}
        <span>trois demi-journées</span>
      </p>
      <div
        className="programInfos"
        style={{
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >

        <div
          className="programInfosSection"
          style={{
            width: isMobile ? '100%' : '30%',
          }}
        >
          <p className="programInfosTitle">
            1/2 journée sur la
            {' '}
            <span className="redText">démarche diagnostique</span>
            {' '}

          </p>
          <div className="programInfosContent">
            <p style={{ margin: 3 }}>- Quand évoquer le TDA/H chez un adulte ?</p>
            <p style={{ margin: 3 }}>- Déroulé de l'évalution diagnostique</p>
            <p style={{ margin: 3 }}>- Les pièges à éviter</p>
            <p style={{ margin: 3 }}>- Comorbidités (dont le TSA) et diagnostic différentiel</p>
          </div>
        </div>

        <div
          className="programInfosSection"
          style={{
            width: isMobile ? '100%' : '30%',
          }}
        >
          <p className="programInfosTitle">
            1/2 journée sur le
            {' '}
            <span className="redText">traitement médicamenteux</span>
            {' '}

          </p>
          <div className="programInfosContent">
            <p style={{ margin: 3 }}>- Quand proposer un traitement médicamenteux ?</p>
            <p style={{ margin: 3 }}>- Comment le mettre en place et le suivre ?</p>
            <p style={{ margin: 3 }}>- Présentation de l'accompagnement non médicamenteux</p>
          </div>
        </div>

        <div
          className="programInfosSection"
          style={{
            width: isMobile ? '100%' : '30%',
          }}
        >
          <p className="programInfosTitle">
            1/2 journée d'
            <span className="redText">intervision</span>
            {' '}

          </p>
          <div className="programInfosContent">
            <p>
              - Analyse et discussion en groupe de cas
              et de situations cliniques complexes

            </p>
          </div>
        </div>
      </div>
      <p style={{ fontStyle: 'italic', margin: 0, fontSize: '13px' }}>
        <span style={{ fontWeight: 'bold' }}>
          Horaires :
          {' '}
          <span style={{ color: '#BC000D' }}>9H-13h.</span>
        </span>
        {' '}
        Un buffet
        sera offert à 13h pour permettre des échanges informels

      </p>
      <div className="registerTitle" style={{ fontWeight: 'bold', marginTop: '2%' }}>Sessions disponibles</div>
      <div className="cardsContainer">
        {sessions.map((session) => (
          <RegisterCard
            key={session.id}
            session={session}
          />
        ))}
      </div>

    </div>
  );
}

export default Register;
