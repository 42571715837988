/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useContext } from 'react';

import { supabaseContext } from '../App';
import { Testimony } from '../interfaces/Testimony';

import './Components.css';

interface Props {
  testimony: Testimony,
  reload: () => void,
}

function TestimonyCardBO(props: Props) {
  const supabase = useContext(supabaseContext);
  const { testimony, reload } = props;
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');

  const deleteTestimony = async () => {
    const { error } = await supabase.from('testimonies').delete()
      .eq('id', testimony.id);
    if (error) setErr('Impossible de supprimer le témoignage');
    if (!error) reload();
  };

  const getColor = () => {
    if (testimony.id % 4 === 0) {
      return '#FAE7FF';
    }
    if (testimony.id % 3 === 0) {
      return '#FFE7E7';
    }
    if (testimony.id % 2 === 0) {
      return '#FFCFE6';
    }
    return '#EDA3A3';
  };

  return (
    <div className="containerTestimonyCard" style={{ backgroundColor: getColor() }}>
      <div className="coreTestimonyCard">
        {testimony.core}

      </div>
      <div className="row" style={{ width: '100%', justifyContent: 'flex-end' }}>
        <div className="authorTestimonyCard">
          {testimony.author}

        </div>
        {err && <div style={{ color: 'red' }}>{err}</div>}
        <div
          className="buttonAgenda"
          role="button"
          tabIndex={0}
          style={{ height: '30px', width: '150px' }}
          onClick={() => setConfirmModalIsOpen(true)}
        >
          🗑️ Supprimer
        </div>

      </div>
      {confirmModalIsOpen && (
      <div className="modal">
        <div className="containerModal" style={{ width: '600px', height: '150px', borderRadius: '20px' }}>
          Vous allez supprimer ce témoignage, confirmer ?
          <div className="row">
            <div
              className="buttonAgenda"
              role="button"
              tabIndex={0}
              onClick={() => setConfirmModalIsOpen(false)}
            >
              Annuler
            </div>
            <div
              className="buttonAgenda"
              role="button"
              tabIndex={0}
              onClick={deleteTestimony}
            >
              Confirmer
            </div>
          </div>
        </div>

      </div>
      )}
    </div>

  );
}

export default TestimonyCardBO;
