/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useState, useCallback, useEffect, useContext,
} from 'react';

import { supabaseContext } from '../App';
import { Session } from '../interfaces/Session';
import { User } from '../interfaces/User';

interface Props {
  session: Session,
  reload: () => void,
}

const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
];

function RegisterCardBO(props:Props) {
  const supabase = useContext(supabaseContext);
  const { session, reload } = props;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<User>>([]);

  const deleteSession = async () => {
    const { error } = await supabase.from('sessions').delete()
      .eq('id', session.id);
    if (!error) reload();
  };

  const firstEvent = new Date(session.firstEvent);
  const secondEvent = new Date(session.secondEvent);
  const thirdEvent = new Date(session.thirdEvent);

  const getUsers = useCallback(async () => {
    const { data } = await supabase.from('users').select(`
    firstName,
    name,
    phone,
    mail,
    address,
    session_id
  `).eq('session_id', session.id);
    if (data) setUsers(data);
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className="registerCardContainer">
        <div className="registerCardTitle">
          {session?.month?.toUpperCase()}
        </div>
        <div className="registerCardDates">
          -
          {' '}
          {firstEvent.getDate()}
          {' '}
          {monthNames[firstEvent.getMonth()]}
          {' '}
          (
          {' '}
          {firstEvent.getHours()}
          h -
          {' '}
          {firstEvent.getHours() + 4}
          h )
        </div>
        <div className="registerCardDates">
          -
          {' '}
          {secondEvent.getDate()}
          {' '}
          {monthNames[secondEvent.getMonth()]}
          {' '}
          (
          {' '}
          {secondEvent.getHours()}
          h -
          {' '}
          {secondEvent.getHours() + 4}
          h )
        </div>
        <div className="registerCardDates">
          -
          {' '}
          {thirdEvent.getDate()}
          {' '}
          {monthNames[thirdEvent.getMonth()]}
          {' '}
          (
          {' '}
          {thirdEvent.getHours()}
          h -
          {' '}
          {thirdEvent.getHours() + 4}
          h )
        </div>
        <div className="registerCardPrice">
          900€
        </div>
        <div className="column" style={{ gap: '5px' }}>
          <div
            className="buttonAgenda"
            role="button"
            tabIndex={0}
            onClick={() => setModalIsOpen(true)}
          >
            Voir les inscrits
          </div>
          <div
            className="buttonAgenda"
            role="button"
            tabIndex={0}
            onClick={() => setConfirmModalIsOpen(true)}
          >
            🗑️ Supprimer
          </div>
        </div>
      </div>
      {modalIsOpen && (
      <div className="modal">
        <div className="containerModal">
          <div className="column">
            <div className="row" style={{ marginBottom: '4px', width: '100%', fontWeight: 'bold' }}>
              <div style={{ width: '15%' }}>
                Prénom
              </div>
              <div style={{ width: '15%' }}>
                Nom
              </div>
              <div style={{ width: '30%' }}>
                Téléphone
              </div>
              <div style={{ width: '30%' }}>
                Mail
              </div>
              <div style={{ width: '30%' }}>
                Addresse pro
              </div>
            </div>
            {users.map((user) => (
              <div className="row" style={{ gap: '20px', width: '100%' }}>
                <div style={{ width: '15%' }}>
                  {user.firstName}
                </div>
                <div style={{ width: '15%' }}>
                  {user.name}
                </div>
                <div style={{ width: '30%' }}>
                  {user.phone}
                </div>
                <div style={{ width: '30%' }}>
                  {user.mail}
                </div>
                <div style={{ width: '30%' }}>
                  {user.address}
                </div>
              </div>
            ))}

          </div>

          <div
            className="buttonAgenda"
            role="button"
            tabIndex={0}
            onClick={() => setModalIsOpen(false)}
          >
            Fermer
          </div>
        </div>

      </div>
      )}
      {confirmModalIsOpen && (
      <div className="modal">
        <div className="containerModal" style={{ width: '600px', height: '150px', borderRadius: '20px' }}>
          Vous allez supprimer cette session, confirmer ?
          <div className="row">
            <div
              className="buttonAgenda"
              role="button"
              tabIndex={0}
              onClick={() => setConfirmModalIsOpen(false)}
            >
              Annuler
            </div>
            <div
              className="buttonAgenda"
              role="button"
              tabIndex={0}
              onClick={() => deleteSession()}
            >
              Confirmer
            </div>
          </div>
        </div>

      </div>
      )}
    </>
  );
}

export default RegisterCardBO;
