import {
  useState, useEffect, useCallback, useContext,
} from 'react';
import './Views.css';
import { Link } from 'react-router-dom';
import { isMobileContext } from '../App';

function Presentation() {
  const [scroll, setScroll] = useState<number>(0);
  const isMobile = useContext<boolean>(isMobileContext);

  const onScroll = useCallback(() => {
    const layout: Element = document.getElementsByClassName('layout')[0];
    const scrollValue: number = layout.scrollTop;

    setScroll(scrollValue);
  }, [document]);

  useEffect(() => {
    const layout: Element = document.getElementsByClassName('layout')[0];

    layout.addEventListener('scroll', onScroll);
    return () => {
      layout.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="containerPresentation">
      <div className="layout">
        <div className="textLayout" style={{ transform: isMobile ? '' : 'translateZ(-0.5px) scale(1.5)' }}>

          <div className={scroll > 100 ? 'headerScrolled' : 'header'}>
            <h1 className="title1">Transfert de compétences</h1>
            <h2 className="title2">"Diagnostic et prise en charge du TDA/H chez l'adulte"</h2>
            <h3 className="title3">par les docteurs Isabelle Laffont et Anne Claret-Tournier.</h3>
          </div>

          <div className="sections" style={{ width: isMobile ? '100%' : '45%' }}>
            <div className="section">
              <p className="sectionText ">
                Fortes de leur expérience d'une dizaine d'années de consultation TDA/H pour adultes,
                en libéral et à l'hôpital (Sainte-Anne, Pitié Salpêtrière), les docteurs Isabelle
                Laffont et Anne Claret-Tournier vous proposent un transfert de compétences.
              </p>
            </div>
            <div className="infoToolTip">
              <div>
                <p className="infoToolTipText">"20% des patients psychiatriques ont un TDA/H"</p>
                <p>(Gerhand et al, Int J Psychiatry Clin Pract. 2021)</p>
              </div>
              <p className="infoToolTipText">Prévalence de 3% chez l'adulte en population générale.</p>
            </div>
            <div className="section">
              <p className="sectionTitle ">
                NOTRE OBJECTIF ?
              </p>
              <p className="sectionText ">
                Vous donner confiance dans votre capacité à diagnostiquer et prendre en charge
                le Trouble Déficit de l'Attention / Hyperactivité chez l'adulte.
              </p>
            </div>
            {isMobile ? <img alt="image2" src="/photo1.jpg" width="80%" style={{ justifySelf: 'center' }} /> : <div className="horizontalSeparator" />}
            <div className="section">
              <p className="sectionTitle ">
                LE MOMENT EST VENU
              </p>
              <p className="sectionText ">
                Il est temps que chaque psychiatre se forme au diagnostic et à la prise en charge du
                TDA/H chez l'adulte.
              </p>
              <p className="sectionText ">
                En juin 2022, la Ritaline a obtenu l'AMM chez l'adulte et le remboursement.
                La prescription initiale peut à présent être effectuée en libéral.
              </p>
            </div>
            {isMobile ? <img alt="image3" src="/photo6.jpg" width="70%" style={{ justifySelf: 'center' }} /> : <div className="horizontalSeparator" />}
            <div className="section">
              <p className="sectionTitle ">
                UNE FORMATION COMPLÈTE ET OPÉRATIONNELLE
              </p>
              <p className="sectionText ">
                Vivante et Interactive, en petit groupe de 15 médecins,
                illustrée par de nombreux cas cliniques, des chiffres sourcés et
                les dernières connaissances scientifiques.
              </p>
            </div>
            {isMobile ? <img alt="image3" src="/photo3.jpg" width="70%" style={{ justifySelf: 'center' }} /> : <div className="horizontalSeparator" />}
            <div className="section">
              <p className="sectionTitle ">
                LE LIEU ET L'APPROCHE
              </p>
              <p className="sectionText ">
                Vous êtes reçus au coeur d'une maison du XVIIIe arrondissement de Paris, dans
                un cadre bienveillant, non compétitif, pour découvrir une approche
                passionnante de la neurodiversité, à la croisée des disciplines ( psychiatrie,
                clinique du neurodeveloppement, pharmacologie,
                addictologie,
                {' '}
                <span style={{ whiteSpace: 'nowrap' }}>somnologie... )</span>
              </p>
            </div>
            {isMobile ? <img alt="image3" src="/photo7.jpg" width="70%" style={{ justifySelf: 'center' }} /> : <div className="horizontalSeparator" />}
            <div className="section">
              <p className="sectionTitle ">
                OUVRAGES THÉMATIQUES (TDA/H, TSA) EN CONSULTATION LIBRE
              </p>
              <p className="sectionText ">
                Ouvrages scientifiques, essais, romans, BD...
              </p>
            </div>
            {isMobile ? <img alt="image4" src="/photo4.jpg" width="70%" style={{ justifySelf: 'center' }} height="400px" /> : <div className="horizontalSeparator" />}
            <div className="section">
              <p className="sectionTitle ">
                VOUS SERONT OFFERTS
              </p>
              <p className="sectionText ">
                Carnets et stylos avec un ensemble de documents
              </p>
              <p className="sectionText ">
                Petit déjeuner, pause thé/café et buffet végétarien maison au déjeuner
              </p>
            </div>
            <Link className="registerButton" to="/register" style={{ marginBottom: '50px', justifySelf: 'center' }}>
              S'inscrire
            </Link>
          </div>
        </div>

        {!isMobile && (
        <div className="imgLayout">
          <div className="photo1" />
          <div className="photo2" />
          <div className="photo6" />
          <div className="photo7" />
          <div className="photo3" />
          <div className="photo5" />
          <div className="photo4" />
        </div>
        )}

      </div>

    </div>
  );
}

export default Presentation;
