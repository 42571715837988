import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HelmetProvider>
    <App />
    <Helmet>
      <title>TDAH de l'adulte : Transfert de compétences</title>
      <link rel="canonical" href="https://formation-tdah.org/" />
    </Helmet>
  </HelmetProvider>,
);
