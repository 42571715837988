import {
  useEffect, useState, useCallback, useContext,
} from 'react';

import { supabaseContext } from '../App';
import ArticleCard from '../components/ArticleCard';
import { Article } from '../interfaces/Article';

function Documentation() {
  const supabase = useContext(supabaseContext);
  const [articles, setArticles] = useState<Array<Article>>([]);

  const getArticles = useCallback(async () => {
    const { data } = await supabase.from('articles').select(`
    id,
    title,
    author,
    content,
    imgurl,
    date
  `);
    if (data) setArticles(data);
  }, []);

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div className="containerArticles">
      <div className="articlesWrapper" style={{ width: '100%', gap: '40px', marginBottom: '50px' }}>
        {articles.map((article) => (
          <ArticleCard key={article.id} article={article} />))}

      </div>
    </div>
  );
}

export default Documentation;
