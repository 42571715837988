/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect, useState, useCallback, ChangeEvent, useContext,
} from 'react';

import { supabaseContext } from '../../../App';
import { Article } from '../../../interfaces/Article';
import ArticleCardBO from '../../../components/ArticleCardBO';

const initArticle: Article = {
  id: 0,
  title: '',
  author: '',
  content: '',
  imgurl: '',
  date: new Date(),
};

function Articles() {
  const supabase = useContext(supabaseContext);
  const [articles, setArticles] = useState<Array<Article>>([]);
  const [newArticle, setNewArticle] = useState<Article>(initArticle);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const getArticles = useCallback(async () => {
    const { data } = await supabase.from('articles').select(`
    id,
    title,
    author,
    content,
    imgurl,
    date
  `);
    if (data) setArticles(data);
  }, []);

  const reload = () => {
    getArticles();
  };

  const onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewArticle({ ...newArticle, title: e.currentTarget.value });
  };
  const onAuthorChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewArticle({ ...newArticle, author: e.currentTarget.value });
  };

  const onSave = async () => {
    const previousIndex = articles[articles.length - 1]?.id || 0;

    const content = (document.getElementById('content') as HTMLInputElement).value || '';

    const { error } = await supabase
      .from('articles')
      .insert({ ...newArticle, id: previousIndex + 1, content });

    if (!error) {
      reload();
      setModalIsOpen(false);
    }
  };

  async function uploadFile(file: File) {
    await supabase.storage.from('Images').upload(newArticle.title.substr(0, 8), file);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    Array.from(files || []).forEach((file) => uploadFile(file));
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <>
      <div className="containerAgenda">
        <div className="articlesWrapper">
          {articles.length === 0 && <div>AUCUN ARTICLE EN LIGNE</div>}
          {articles && articles.map((article) => (
            <ArticleCardBO
              key={article.id}
              reload={reload}
              article={article}
            />
          ))}
        </div>
        <div
          className="registerButton"
          role="button"
          tabIndex={0}
          style={{ width: '20%', minHeight: '40px', marginTop: '70px' }}
          onClick={() => setModalIsOpen(!modalIsOpen)}
        >
          Ajouter article
        </div>
      </div>

      {modalIsOpen && (
      <div className="modalAddSession">
        <div className="containerAddSession" style={{ height: '80%', width: '80%' }}>
          <div>AJOUTER UN ARTICLE</div>
          <div className="row">
            <div className="column">
              <div>Titre</div>
              <input
                type="text"
                value={newArticle.title}
                onChange={onTitleChange}
              />
              <div>Auteur</div>
              <input
                type="text"
                value={newArticle.author}
                onChange={onAuthorChange}
              />
              <div>Contenu</div>
              <textarea
                className="articleCoreInput"
                id="content"
              />
            </div>
            <input type="file" id="articleImage" name="articleImage" accept="image/png, image/jpeg" onChange={handleChange} />
          </div>
          <div className="row">
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={() => setModalIsOpen(!modalIsOpen)}
            >
              Annuler

            </div>
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={onSave}
            >
              Confirmer

            </div>

          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default Articles;
