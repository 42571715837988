import { useState, useEffect, createContext } from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import ReactPlayer from 'react-player/lazy';
import { createClient } from '@supabase/supabase-js';

import NavBar from './views/navBar/NavBar';
import Contact from './views/Contact';
import Documentation from './views/Documentation';
import Home from './views/home/Home';
import Testimonies from './views/Testimonies';
import NotFound from './views/NotFound';
import Presentation from './views/Presentation';
import Register from './views/Register';
import PaymentValidate from './views/PaymentValidate';
import BackOffice from './views/backoffice/BackOffice';

export const isMobileContext = createContext<boolean>(false);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL as string;
const supabaseApiKey = process.env.REACT_APP_SUPABASE_API_KEY as string;

const supabase = createClient(supabaseUrl, supabaseApiKey);

export const supabaseContext = createContext(supabase);

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(!window.location.pathname.includes('backoffice'));

  const lastVisit: number = new Date().getTime() - Number(window.localStorage.getItem('lastVisitDate'));
  const timeToShowVideoAgain = 86400000;

  // Create isMobile state/context
  const [isMobile, setIsMobile] = useState<boolean>(window.outerWidth <= 768);

  function handleWindowSizeChange() {
    setIsMobile(window.outerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const loadApp = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (lastVisit < timeToShowVideoAgain) {
      setIsLoading(false);
    }
    window.localStorage.setItem('lastVisitDate', JSON.stringify(new Date().getTime()));
  }, []);

  return isLoading && !isMobile ? (
    <div className="videoWrapper">
      <h1 style={{ position: 'absolute', opacity: 0 }}>TDAH de l'adulte : Transfert de connaissance</h1>
      <h2 style={{ position: 'absolute', opacity: 0 }}>
        Fortes de leur expérience d'une dizaine d'années de consultation TDA/H pour adultes,
        en libéral et à l'hôpital (Sainte-Anne, Pitié Salpêtrière), les docteurs Isabelle
        Laffont et Anne Claret-Tournier vous proposent un transfert de compétences.
      </h2>
      <button
        className="skipButton"
        onClick={() => loadApp()}
        type="submit"
      >
        ACCÉDER AU SITE
      </button>
      <ReactPlayer
        className="react-player"
        url="intro.mp4"
        playing
        muted
        width="100%"
        height="100%"
        controls={false}
        onEnded={loadApp}
      />
    </div>
  ) : (
    <div className="App">
      <supabaseContext.Provider value={supabase}>
        <isMobileContext.Provider value={isMobile}>
          <Router>
            <NavBar />
            <Routes>
              <Route path="/contact" element={<Contact />} caseSensitive />
              <Route path="/Contact" element={<Contact />} caseSensitive />
              <Route path="/articles" element={<Documentation />} caseSensitive />
              <Route path="/Articles" element={<Documentation />} caseSensitive />
              <Route path="/testimonies" element={<Testimonies />} caseSensitive />
              <Route path="/Testimonies" element={<Testimonies />} caseSensitive />
              <Route path="/presentation" element={<Presentation />} caseSensitive />
              <Route path="/Presentation" element={<Presentation />} caseSensitive />
              <Route path="/register" element={<Register />} caseSensitive />
              <Route path="/Register" element={<Register />} caseSensitive />
              <Route path="/backoffice" element={<BackOffice />} caseSensitive />
              <Route path="/Backoffice" element={<BackOffice />} caseSensitive />
              <Route path="/" element={isMobile ? <Presentation /> : <Home />} />
              <Route path="/paymentValidate" element={<PaymentValidate />} caseSensitive />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
          </Router>
        </isMobileContext.Provider>
      </supabaseContext.Provider>
    </div>
  );
}

export default App;
