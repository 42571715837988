/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import emailjs from '@emailjs/browser';

import { isMobileContext } from '../App';

function Contact() {
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const isMobile = useContext(isMobileContext);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    setErr('');

    const firstName = (document.getElementById('firstName') as HTMLInputElement).value;
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const message = (document.getElementById('message') as HTMLInputElement).value;
    const mail = (document.getElementById('mail') as HTMLInputElement).value;
    const contactForm = (document.getElementById('contactForm') as HTMLFormElement);

    e.preventDefault();

    if (!firstName || !name || !message || !mail) {
      setErr('Champ(s) non rempli(s)');
      return;
    }

    if (e.currentTarget) {
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE as string, 'template_0s95mv4', e.currentTarget, process.env.REACT_APP_EMAILJS_API_KEY as string)
        .then((result) => {
          console.log(result);
          setMessageSent(true);
          contactForm.reset();
        }, (error) => {
          console.log(error.text);
        });
    }
  };

  return (
    <div className="containerView">
      <div className="containerContact">
        <div>
          N'hésitez pas à nous contacter via ce formulaire :
        </div>
        <form
          onSubmit={sendEmail}
          id="contactForm"
        >
          <div className={isMobile ? 'column' : 'row'} style={{ marginTop: '40px', marginBottom: '40px' }}>
            <div className="column">
              <div>Prénom</div>
              <textarea
                className="contactInput"
                placeholder="Prénom"
                name="firstname"
                id="firstName"
              />
            </div>
            <div className="column">
              <div>Nom</div>
              <textarea
                className="contactInput"
                placeholder="Nom"
                name="name"
                id="name"
              />
            </div>
            <div className="column">
              <div>
                Mail
                <span style={{ color: 'red' }}>*</span>
              </div>
              <textarea
                className="contactInput"
                placeholder="Mail"
                name="mail"
                id="mail"
              />
            </div>
          </div>
          <div className="column">
            <div>
              Message
              <span style={{ color: 'red' }}>*</span>
            </div>
            <textarea
              className="contactInputMessage"
              placeholder="Tapez votre message ici ..."
              name="message"
              id="message"
            />
          </div>
          <div
            className="contactSubmit"
          >
            {err && <div style={{ color: 'red' }}>{err}</div>}
            {messageSent && <div>Message envoyé, merci !</div>}
            {!messageSent && (
            <button
              className="registerButton"
              style={{ border: 'none', marginBottom: '50px' }}
              type="submit"
            >
              Envoyer

            </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
