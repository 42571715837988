/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useState, useEffect, useContext, useCallback,
} from 'react';

import { supabaseContext, isMobileContext } from '../App';
import { Session } from '../interfaces/Session';
import { User } from '../interfaces/User';

interface Props {
  session: Session,
}

const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
];

const newUserInit = {
  firstName: '',
  name: '',
  phone: undefined,
  mail: '',
  address: '',
  session_id: 0,
};

function RegisterCard(props:Props) {
  const supabase = useContext(supabaseContext);
  const { session } = props;
  const [sessionIsFull, setSessionIsFull] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<User>(newUserInit);
  const [err, setErr] = useState<string>('');
  const isMobile = useContext<boolean>(isMobileContext);

  const firstEvent = new Date(session.firstEvent);
  const secondEvent = new Date(session.secondEvent);
  const thirdEvent = new Date(session.thirdEvent);

  const checkIfFull = useCallback(async () => {
    const { data } = await supabase.from('users').select(`
    session_id
  `).eq('session_id', session.id);
    if (data && data.length > 14) setSessionIsFull(true);
  }, []);

  const redirectToStripe = () => {
    const noFirstName = newUser.firstName === '';
    const noLastName = newUser.name === '';
    const noPhone = newUser.phone === undefined;
    const noMail = newUser.mail === '';
    const noAddress = newUser.mail === '';

    if (noFirstName || noLastName || noPhone || noMail || noAddress) {
      setErr('Champs requis manquants');
    } else {
      const sessionInfo = {
        first_session_date: new Date(session.firstEvent).toLocaleDateString(),
        second_session_date: new Date(session.secondEvent).toLocaleDateString(),
        third_session_date: new Date(session.thirdEvent).toLocaleDateString(),
        first_session_time: new Date(session.firstEvent).getHours(),
        second_session_time: new Date(session.secondEvent).getHours(),
        third_session_time: new Date(session.thirdEvent).getHours(),
      };

      window.localStorage.setItem('userInfo', JSON.stringify(newUser));
      window.localStorage.setItem('sessionInfo', JSON.stringify(sessionInfo));
      window.open('https://buy.stripe.com/5kA01j6I303XdPy288', '_self');
    }
  };

  useEffect(() => {
    setNewUser({ ...newUser, session_id: session.id });
    checkIfFull();
  }, []);

  return (
    <>
      <div className="registerCardContainer">
        <div className="registerCardTitle">
          {session?.month?.toUpperCase()}
        </div>
        <div className="registerCardDates">
          -
          {' '}
          {firstEvent.getDate()}
          {' '}
          {monthNames[firstEvent.getMonth()]}
          {' '}
          (
          {' '}
          {firstEvent.getHours()}
          h -
          {' '}
          {firstEvent.getHours() + 4}
          h )
        </div>
        <div className="registerCardDates">
          -
          {' '}
          {secondEvent.getDate()}
          {' '}
          {monthNames[secondEvent.getMonth()]}
          {' '}
          (
          {' '}
          {secondEvent.getHours()}
          h -
          {' '}
          {secondEvent.getHours() + 4}
          h )
        </div>
        <div className="registerCardDates">
          -
          {' '}
          {thirdEvent.getDate()}
          {' '}
          {monthNames[thirdEvent.getMonth()]}
          {' '}
          (
          {' '}
          {thirdEvent.getHours()}
          h -
          {' '}
          {thirdEvent.getHours() + 4}
          h )
        </div>
        <div className="registerCardPrice">
          {session.price}
          €
        </div>
        <div className="column" style={{ gap: '5px' }}>
          {!sessionIsFull ? (
            <div
              className="registerButton"
              role="button"
              tabIndex={0}
              onClick={() => setModalIsOpen(true)}
            >
              S'INSCRIRE
            </div>
          ) : <div>COMPLET</div>}

        </div>
      </div>

      {modalIsOpen && (
        <div className="modal">
          <div className="containerModal" style={{ flexDirection: isMobile ? 'column' : 'row' }}>

            {/* Card */}
            <div className="registerSumUp">
              <div className="registerCardTitle">
                {session?.month?.toUpperCase()}
              </div>
              <div>
                <div style={{ textAlign: 'start' }}>
                  - DÉMARCHE DIAGNOSTIQUE :
                  {' '}
                  {firstEvent.getDate()}
                  {' '}
                  {monthNames[firstEvent.getMonth()]}
                  {' '}
                  (
                  {' '}
                  {firstEvent.getHours()}
                  h -
                  {' '}
                  {firstEvent.getHours() + 4}
                  h )
                </div>
                <div style={{ textAlign: 'start' }}>
                  - TRAITEMENTS :
                  {' '}
                  {secondEvent.getDate()}
                  {' '}
                  {monthNames[secondEvent.getMonth()]}
                  {' '}
                  (
                  {' '}
                  {secondEvent.getHours()}
                  h -
                  {' '}
                  {secondEvent.getHours() + 4}
                  h )
                </div>
                <div style={{ textAlign: 'start' }}>
                  - INTERVISION :
                  {' '}
                  {thirdEvent.getDate()}
                  {' '}
                  {monthNames[thirdEvent.getMonth()]}
                  {' '}
                  (
                  {' '}
                  {thirdEvent.getHours()}
                  h -
                  {' '}
                  {thirdEvent.getHours() + 4}
                  h )
                </div>
              </div>
              <div className="registerCardPrice">
                {session.price}
                €
              </div>
            </div>
            <div className="modalForm">

              {/* Form */}
              <div>
                <div className="registerCardTitle">
                  Inscription
                </div>
                <div>
                  Veuillez remplir le formulaire
                  pour valider votre inscription et passer au paiement.
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div className="row">
                  <div className="column">

                    <div>
                      Prénom
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                    <input
                      type="text"
                      value={newUser.firstName}
                      onChange={(event) => setNewUser({ ...newUser, firstName: event.target.value })}
                    />
                    <div>
                      Téléphone
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                    <input
                      type="number"
                      value={newUser.phone}
                      onChange={(event) => setNewUser({ ...newUser, phone: event.target.valueAsNumber })}
                    />
                  </div>
                  <div className="column">
                    <div>
                      Nom
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                    <input
                      type="text"
                      value={newUser.name}
                      onChange={(event) => setNewUser({ ...newUser, name: event.target.value })}
                    />
                    <div>
                      Mail
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                    <input
                      type="text"
                      value={newUser.mail}
                      onChange={(event) => setNewUser({ ...newUser, mail: event.target.value })}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{ width: '100%' }}
                >
                  <div className="column">
                    <div>
                      Addresse professionnelle
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                    <input
                      type="text"
                      value={newUser.address}
                      style={{ width: '190%' }}
                      onChange={(event) => setNewUser({ ...newUser, address: event.target.value })}
                    />
                  </div>
                </div>
              </div>
              {err ? (
                <div style={{ color: 'red', height: '5px', alignSelf: 'center' }}>
                  {err}
                </div>
              ) : <div style={{ height: '5px' }} />}
              {/* Buttons */}
              <div className="row">
                <div
                  className="registerButton"
                  role="button"
                  tabIndex={0}
                  style={{ padding: '10px', width: '140px' }}
                  onClick={() => setModalIsOpen(false)}
                >
                  Annuler
                </div>
                <div
                  className="registerButton"
                  role="button"
                  tabIndex={0}
                  style={{ padding: '10px', width: '140px' }}
                  onClick={redirectToStripe}
                >
                  Passer au paiement
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RegisterCard;
