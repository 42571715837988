/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useContext, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import Tab from './Tab';
import './NavBar.css';
import { isMobileContext } from '../../App';

function NavBar() {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<string>('home');
  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false);
  const isMobile = useContext<boolean>(isMobileContext);

  useEffect(() => {
    setActiveTab(location.pathname.slice(1));
  }, [location]);

  return !isMobile ? (
    <div className="navbarContainer">
      <Tab
        route="/"
        label=""
        isActive={activeTab === 'home'}
        setActiveTab={() => setActiveTab('home')}
      />
      <Tab
        route="/presentation"
        label="Présentation"
        isActive={activeTab === 'presentation'}
        setActiveTab={() => setActiveTab('presentation')}
      />
      <Tab
        route="/articles"
        label="Articles"
        isActive={activeTab === 'articles'}
        setActiveTab={() => setActiveTab('articles')}
      />
      <Tab
        route="/testimonies"
        label="Témoignages"
        isActive={activeTab === 'testimonies'}
        setActiveTab={() => setActiveTab('testimonies')}
      />
      <Tab
        route="/contact"
        label="Contact"
        isActive={activeTab === 'contact'}
        setActiveTab={() => setActiveTab('contact')}
      />

      <Link
        to="/register"
        className="registerButton"
        onClick={() => setActiveTab('register')}
      >
        S'inscrire
      </Link>
    </div>
  ) : (
    <div className="mobileNavbarContainer">
      <img className="logo" src="logo.jpg" alt="logo" />
      <div
        role="button"
        tabIndex={0}
        onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}
      >
        <img
          className="burgerMenuLogo"
          src="burgerMenu.png"
          alt="burgerMenu"
        />
      </div>

      {burgerMenuOpen && (
      <div className="burgerMenu">
        <Tab
          route="/presentation"
          label="Présentation"
          isActive={activeTab === 'presentation'}
          setActiveTab={() => { setActiveTab('presentation'); setBurgerMenuOpen(false); }}
        />
        <Tab
          route="/articles"
          label="Articles"
          isActive={activeTab === 'articles'}
          setActiveTab={() => { setActiveTab('articles'); setBurgerMenuOpen(false); }}
        />
        <Tab
          route="/testimonies"
          label="Témoignages"
          isActive={activeTab === 'testimonies'}
          setActiveTab={() => { setActiveTab('testimonies'); setBurgerMenuOpen(false); }}
        />
        <Tab
          route="/contact"
          label="Contact"
          isActive={activeTab === 'contact'}
          setActiveTab={() => { setActiveTab('contact'); setBurgerMenuOpen(false); }}
        />

        <Link
          to="/register"
          className="registerButton"
          onClick={() => { setActiveTab('register'); setBurgerMenuOpen(false); }}
        >
          S'inscrire
        </Link>
      </div>
      )}
    </div>
  );
}

export default NavBar;
