import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  const [isAnimationFinished, setIsAnimationFinished] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimationFinished(true);
    }, 1900);
  }, []);

  return (
    <div className="homeContainer">
      {!isAnimationFinished && <img className="homeLogo" src="logo.jpg" alt="logo" />}
      <img className="homeLogoAnimated" src="logoHome.jpg" alt="logo" />
      <Link
        className="homeTab"
        style={{
          left: '16vw',
          top: '7vw',
          width: 'calc(100px + 10%)',
          height: 'calc(10px + 8%)',
          fontSize: 'calc(8px + 2vh)',
        }}
        to="/presentation"
      >
        PRESENTATION
      </Link>
      <Link
        className="homeTab"
        to="/testimonies"
        style={{
          left: '18vw',
          top: '32vw',
          width: 'calc(80px + 5%)',
          height: 'calc(10px + 5%)',
        }}
      >
        TEMOIGNAGES
      </Link>
      <Link
        className="homeTab"
        to="/register"
        style={{
          left: '68vw',
          top: '16vw',
          width: 'calc(100px + 10%)',
          height: 'calc(10px + 8%)',
          fontSize: 'calc(8px + 2vh)',
        }}
      >
        S'INSCRIRE
      </Link>
      <Link
        className="homeTab"
        to="/contact"
        style={{
          left: '70vw',
          top: '36vw',
          width: 'calc(80px + 5%)',
          height: 'calc(10px + 5%)',
        }}
      >
        CONTACT
      </Link>
      <Link
        className="homeTab"
        to="/articles"
        style={{
          left: '60vw',
          top: '7vw',
          width: 'calc(80px + 5%)',
          height: 'calc(10px + 5%)',
        }}
      >
        ARTICLES
      </Link>
      <Link
        className="homeTab"
        to="/"
        style={{
          left: '31vw',
          top: '41vw',
          width: 'calc(80px + 5%)',
          height: 'calc(10px + 5%)',
        }}
      >
        LIVRES
      </Link>
    </div>
  );
}

export default Home;
