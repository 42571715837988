import './NavBar.css';

import { Link } from 'react-router-dom';

interface TabProps {
  route: string,
  label: string,
  isActive: boolean,
  setActiveTab: () => void;
}

function Tab(props: TabProps) {
  const {
    route, label, isActive, setActiveTab,
  } = props;

  return (
    <Link
      to={route}
      className={isActive ? 'activeTab' : 'defaultTab'}
      onClick={() => setActiveTab()}
    >
      {label}
      {!label && <img className="logo" src="logo.jpg" alt="logo" />}
    </Link>
  );
}

export default Tab;
