import {
  useEffect, useState, useCallback, useContext,
} from 'react';

import './Views.css';
import { supabaseContext } from '../App';
import TestimonyCard from '../components/TestimonyCard';
import { Testimony } from '../interfaces/Testimony';

function Testimonies() {
  const supabase = useContext(supabaseContext);
  const [testimonies, setTestimonies] = useState<Array<Testimony>>([]);

  const getTestimonies = useCallback(async () => {
    const { data } = await supabase.from('testimonies').select(`
    id, 
    author,
    core,
    color
  `);
    if (data) setTestimonies(data);
  }, []);

  useEffect(() => {
    getTestimonies();
  }, []);

  return (
    <div className="containerTestimonies">
      <div className="column" style={{ width: '100%', gap: '40px', marginBottom: '50px' }}>
        {testimonies.map((testimony) => (
          <TestimonyCard key={testimony.id} testimony={testimony} />))}

      </div>
    </div>
  );
}

export default Testimonies;
