import { Testimony } from '../interfaces/Testimony';

import './Components.css';

interface Props {
  testimony: Testimony,
}

function TestimonyCard(props: Props) {
  const { testimony } = props;

  return (
    <div className="containerTestimonyCard">
      <div className="coreTestimonyCard">
        "
        {testimony.core}
        "

      </div>
      <div className="row" style={{ width: '80%', justifyContent: 'flex-end', fontWeight: 'bold' }}>
        <div className="authorTestimonyCard">
          {testimony.author}

        </div>
      </div>
    </div>
  );
}

export default TestimonyCard;
